import React from 'react';
import {
  useHistory,
  useLocation,
} from 'react-router-dom';
import {
  AlertOutlined,
  GroupOutlined, HistoryOutlined,
  LogoutOutlined,
  TeamOutlined,
} from '@ant-design/icons';
import { useQueryClient } from '@tanstack/react-query';
import { Menu, Button } from 'antd';
import { logout } from '../../services/Admin';
import './index.less';

function AppBar() {
  const location = useLocation();
  const history = useHistory();
  const queryClient = useQueryClient();

  const onClick = () => {
    logout();
    localStorage.removeItem('sessionId');
    queryClient.clear();
    history.push('/auth');
  };

  return (
    <div id="AppBar">
      <Menu
        mode="horizontal"
        selectedKeys={[location.pathname.split('/')[1]]}
        style={{ minWidth: 370 }}
      >
        <Menu.Item key="workspaces" icon={<GroupOutlined />}>
          <a href="#/workspaces">Workspaces</a>
        </Menu.Item>
        <Menu.Item key="users" icon={<TeamOutlined />}>
          <a href="#/users">Users</a>
        </Menu.Item>
        <Menu.Item key="events" icon={<AlertOutlined />}>
          <a href="#/events">Events</a>
        </Menu.Item>
        <Menu.Item key="history" icon={<HistoryOutlined />}>
          <a href="#/history">History</a>
        </Menu.Item>
      </Menu>
      <div className="Fill" />
      <Button
        icon={<LogoutOutlined />}
        onClick={onClick}
      >
        Log out
      </Button>
    </div>
  );
}

export default AppBar;
