import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Modal, Form, Input, Button,
} from 'antd';

function SendMessageModal({
  show, onCancel, onSubmit, loading,
}) {
  const [form] = Form.useForm();
  useEffect(() => {
    form.resetFields();
  }, [show]);
  return (
    <Modal
      visible={show}
      title="Write your message here"
      footer={null}
      closable={false}
    >
      <Form form={form} onFinish={onSubmit} disabled={loading}>
        <Form.Item noStyle name="text">
          <Input.TextArea
            rows={4}
            placeholder="Type your message here!"
            style={{ resize: 'none' }}
          />
        </Form.Item>
        <Form.Item noStyle>
          <div style={{ textAlign: 'right', marginTop: 16 }}>
            <Button onClick={onCancel}>
              CANCEL
            </Button>
            {' '}
            <Button htmlType="Submit" type="primary" loading={loading}>
              SEND
            </Button>
          </div>
        </Form.Item>
      </Form>
    </Modal>
  );
}

SendMessageModal.propTypes = {
  show: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default SendMessageModal;
