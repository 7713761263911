import React from 'react';
import {
  Redirect,
  Route,
  Switch,
  useLocation,
} from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { serializeError } from 'serialize-error';
import Workspaces from './Workspaces';
import Users from './Users';
import Events from './Events';
import History from './History';
import r from '../../utils/wrap-route-component';
import { getProfile } from '../../services/Admin';
import AppBar from '../../components/AppBar';
import Loader from '../../components/Loader';

function AppWrapper() {
  const location = useLocation();

  const redirectUrl = `/auth?next=${encodeURIComponent(JSON.stringify(location))}`;

  const hasSessionId = !!localStorage.getItem('sessionId');
  if (!hasSessionId) {
    return <Redirect to={redirectUrl} />;
  }

  const { isLoading, data, error } = useQuery(['getProfile'], getProfile, { retry: false });
  if (error?.response?.data?.error?.message === 'no sessionid found') {
    localStorage.removeItem('sessionId');
    window.location.reload(); // weird but fix a bug
    return null;
  }
  if (error) {
    return (
      <>
        {`Unknown Error: ${JSON.stringify(error.response || serializeError(error))}`}
        <br />
        --------------------
        <br />
        Please reload the page.
      </>
    );
  }

  return (
    <>
      <AppBar />
      {isLoading ? <Loader /> : null}
      <div className="PageWrapper">
        <Switch>
          <Route path="/workspaces" exact render={r(Workspaces, !!data)} />
          <Route path="/users" exact render={r(Users, !!data)} />
          <Route path="/events" exact render={r(Events, !!data)} />
          <Route path="/history" exact render={r(History, !!data)} />
          <Redirect from="*" to="/workspaces" />
        </Switch>
      </div>
    </>
  );
}

export default AppWrapper;
