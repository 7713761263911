import Api from './Api';
import onlyEligibleFilter from '../utils/only-eligible-filter';

export const login = async (values) => {
  const result = await Api.post('/admin/login', values);
  return result.data;
};

export const logout = async () => {
  Api.post('/admin/logout');
};

export const getProfile = async () => {
  const result = await Api.post('/admin/get-profile');
  return result.data;
};

export const fetch = async ({
  model,
  method,
  filter,
  sort,
  projection,
  skip = 0,
  limit = 100,
}) => {
  const result = await Api.post('/admin/fetch', {
    model,
    method,
    filter,
    sort,
    projection,
    skip,
    limit,
  });
  return result.data;
};

export const listWorkspaces = () => fetch({
  model: 'SlackWorkspace',
  method: 'find',
  filter: { state: { $nin: ['jy_automation'] } },
  sort: { _id: -1 },
  limit: 10000,
});

export const listMsOrganizations = () => fetch({
  model: 'MsOrganization',
  method: 'find',
  filter: {},
  sort: { _id: -1 },
  limit: 10000,
});

export const countWorkspaces = () => fetch({
  model: 'SlackWorkspace',
  filter: { state: { $nin: ['jy_automation'] } },
  method: 'countDocuments',
});

export const countMsOrganizations = () => fetch({
  model: 'MsOrganization',
  filter: {},
  method: 'countDocuments',
});

function buildSlackUserFilter(entityId, onlyEligible = false, q = '') {
  return {
    teamId: entityId,
    ...(onlyEligible ? onlyEligibleFilter : {}),
    ...(q ? {
      $or: [
        { 'data.real_name': { $regex: q, $options: 'i' } },
        { 'data.profile.real_name': { $regex: q, $options: 'i' } },
        { 'data.profile.email': { $regex: q, $options: 'i' } },
        { 'data.profile.title': { $regex: q, $options: 'i' } },
        { userId: { $regex: q, $options: 'i' } },
      ],
    } : {}),
  };
}

function buildMsUserFilter(entityId, q = '') {
  return {
    tenantId: entityId,
    ...(q ? {
      $or: [
        { 'data.name': { $regex: q, $options: 'i' } },
        { 'data.email': { $regex: q, $options: 'i' } },
        { aadObjectId: { $regex: q, $options: 'i' } },
      ],
    } : {}),
  };
}

export const listUsers = (type, page, entityId, onlyEligible = false, q = '') => fetch({
  ...(type === 'slack' ? {
    model: 'SlackUser',
    filter: buildSlackUserFilter(entityId, onlyEligible, q),
  } : {
    model: 'MsUser',
    filter: buildMsUserFilter(entityId, q),
  }),
  method: 'find',
  sort: { _id: -1 },
  skip: (page - 1) * 20,
  limit: 20,
});

export const countUsers = (type, entityId, onlyEligible = false, q = '') => fetch({
  ...(type === 'slack' ? {
    model: 'SlackUser',
    filter: buildSlackUserFilter(entityId, onlyEligible, q),
  } : {
    model: 'MsUser',
    filter: buildMsUserFilter(entityId, q),
  }),
  method: 'countDocuments',
});

export const workspacesStats = async (teamIds) => {
  const result = await Api.post('/admin/workspaces-stats', {
    teamIds,
  });
  return result.data;
};

export const msOrganizationsStats = async (tenantIds) => {
  const result = await Api.post('/admin/org-stats', {
    tenantIds,
  });
  return result.data;
};

export const listWorkspacesWithStats = async () => {
  const workspaces = await listWorkspaces();
  const stats = await workspacesStats(workspaces.map((w) => w.teamId));
  return workspaces.map((w) => ({
    ...w,
    stats: stats[w.teamId],
  }));
};

export const listMsOrganizationsWithStats = async () => {
  const orgs = await listMsOrganizations();
  const stats = await msOrganizationsStats(orgs.map((w) => w.tenantId));
  return orgs.map((w) => ({
    ...w,
    stats: stats[w.tenantId],
  }));
};

export const listPastEvents = (teamId) => fetch({
  model: 'SlackMessage',
  method: 'find',
  filter: {
    type: 'CELEBRATION',
    teamId,
  },
  sort: { _id: -1 },
});

export const countPastEvents = (teamId) => fetch({
  model: 'SlackMessage',
  method: 'countDocuments',
  filter: {
    type: 'CELEBRATION',
    teamId,
  },
});

export const listEvents = (page, teamId, month) => fetch({
  model: 'SlackEvent',
  method: 'find',
  filter: {
    day: { $ne: null },
    month: month || { $ne: null },
    teamId,
  },
  sort: { month: 1, day: 1 },
  skip: (page - 1) * 20,
  limit: 20,
});

export const countEvents = (teamId, month) => fetch({
  model: 'SlackEvent',
  method: 'countDocuments',
  filter: {
    day: { $ne: null },
    month: month || { $ne: null },
    teamId,
  },
});

export const sendMessage = async (channel, teamId, text) => {
  const result = await Api.post('/admin/send-message', {
    channel,
    teamId,
    text,
  });
  return result.data;
};

export const deleteWorkspace = async (workspaceId) => {
  const result = await Api.post('/admin/delete-workspace', {
    workspaceId,
  });
  return result.data;
};

export const grantFreePlan = async (entityId) => {
  const result = await Api.post('/admin/grant-free-plan', {
    entityId,
  });
  return result.data;
};
