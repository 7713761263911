import React from 'react';
import moment from 'moment';
import { Pagination, Table, Select } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import qs from 'qs';
import { useQuery } from '@tanstack/react-query';
import { countEvents, listEvents } from '../../services/Admin';

function Events() {
  const location = useLocation();
  const history = useHistory();
  const search = qs.parse(location.search, { ignoreQueryPrefix: true });
  const currentPage = +search.page || 1;
  const onPaginationChange = (page) => {
    const newSearch = {
      ...search,
      page,
    };
    history.push({
      ...location,
      search: qs.stringify(newSearch),
    });
  };
  const onMonthChange = (value) => {
    const newSearch = {
      ...search,
      month: value,
    };
    history.push({
      ...location,
      search: qs.stringify(newSearch),
    });
  };
  const {
    isLoading,
    data: events,
  } = useQuery(
    ['listEvents', search.teamId, currentPage, search.month],
    () => listEvents(currentPage, search.teamId, search.month || undefined),
  );
  const {
    data: count,
    isLoading: isCountLoading,
  } = useQuery(
    ['countEvents', search.teamId, search.month],
    () => countEvents(search.teamId, search.month || undefined),
  );
  return (
    <div id="History">
      <div className="Filters">
        <Select
          placeholder="Month"
          defaultValue={+search.month || undefined}
          style={{ width: 200 }}
          onChange={onMonthChange}
        >
          <Select.Option />
          {new Array(12).fill('').map((_, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Select.Option key={index} value={index + 1}>
              {moment().month(index).format('MMMM')}
            </Select.Option>
          ))}
        </Select>
        <div style={{ flex: 1 }} />
        <Pagination
          current={currentPage}
          onChange={onPaginationChange}
          total={count}
          pageSize={20}
          showSizeChanger={false}
        />
      </div>
      <Table
        dataSource={events}
        loading={isLoading}
        pagination={false}
        rowKey="_id"
        size="small"
        scroll={{ x: 1500 }}
      >
        <Table.Column
          title={`TeamId${isCountLoading ? '' : ` (${count})`}`}
          dataIndex="teamId"
          key="teamId"
        />
        <Table.Column
          title="User"
          dataIndex="userId"
          key="userId"
        />
        <Table.Column
          title="Type"
          dataIndex="type"
          key="type"
        />
        <Table.Column
          title="Month"
          dataIndex="month"
          key="month"
        />
        <Table.Column
          title="Day"
          dataIndex="day"
          key="day"
        />
      </Table>
    </div>
  );
}

export default Events;
