import React from 'react';
import { numberWithCommas } from '@src/utils/utils';
import moment from 'moment/moment';
import { dateFromMongoId } from '@src/utils/mongo-id';
import PropTypes from 'prop-types';

function StatisticVignette({
  icon,
  title,
  content,
  subcontent,
  showPercentage,
}) {
  return (
    <div className="Workspace_stats_element">
      <div className="Workspace_stats_element_title_wrapper">
        <div className="Workspace_stats_element_icon">{icon}</div>
        <div className="Workspace_stats_element_title">{title}</div>
      </div>

      <div className="Workspace_stats_element_content_wrapper">
        <div className="Workspace_stats_element_content">
          {content}
          {showPercentage && (
            <span
              style={{
                fontSize: '16px',
                marginLeft: '8px',
              }}
            >
              %
            </span>
          )}
        </div>
        {subcontent && <div className="Workspace_stats_element_content_sub">{subcontent}</div>}
      </div>
    </div>
  );
}

StatisticVignette.propTypes = {
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  content: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.element,
  ]).isRequired,
  subcontent: PropTypes.string.isRequired,
  showPercentage: PropTypes.bool,
};
StatisticVignette.defaultProps = {
  showPercentage: false,
};

function WorkspacesStat({ workspaces }) {
  const totalCountBirthdays = workspaces
    .reduce((t, w) => t + (w.stats.birthdayCount - w.stats.birthdayOptOutCount), 0);
  const totalCountAnniversaries = workspaces
    .reduce((t, w) => t + (w.stats.workAnniversaryCount - w.stats.workAnniversaryOptOutCount), 0);
  const liveWorkspaces = workspaces
    .filter((w) => (
      (w.teamId && w.status === 'LIVE' && w.installedAt)
      || (w.tenantId && w.teams.filter((t) => (
        t.status === 'LIVE'
        && t.installedAt
      )).length > 0)
    ));
  const pendingWorkspaces = workspaces
    .filter((w) => (
      (w.teamId && w.status === 'LIVE' && !w.installedAt)
      || (w.tenantId && w.teams.filter((t) => (
        t.status === 'LIVE'
        && !t.installedAt
      )).length > 0)
    ));
  const uninstalledWorkspaces = workspaces
    .filter((w) => (
      (w.teamId && w.status !== 'LIVE')
      || (w.tenantId && w.teams.filter((t) => (
        t.status === 'LIVE'
      )).length === 0)
    ));
  // remove uninstalled and optouts
  const totalUsersBirthdays = liveWorkspaces
    .reduce((acc, element) => acc
      + (element.stats.usersCount
        - element.stats.birthdayOptOutCount), 0);
  const totalUsersAnniversaries = liveWorkspaces
    .reduce((acc, w) => acc
      + ((
        (w.teamId && w.workAnniversary)
        || (w.tenantId && !!w.teams.find((t) => (
          t.status === 'LIVE'
          && t.installedAt
          && t.workAnniversary
        )))
      ) ? (w.stats.usersCount
        - w.stats.workAnniversaryOptOutCount) : 0), 0);
  const todayWorkspaces = workspaces
    .filter((w) => moment(dateFromMongoId(w._id))
      .isSame(moment(), 'day'));
  const weekWorkspaces = workspaces
    .filter((w) => moment(dateFromMongoId(w._id))
      .isSame(moment(), 'week'));

  const percentAutocollect = liveWorkspaces.filter((w) => (
    w.teamId && (
      w.version >= 'V3'
        ? w.channels[0]?.autoCollect
        : w.autoCollect
    )
  ) || (
    w.tenantId && w.teams[0]?.autoCollect
  ));
  const totalCelebrations = workspaces.reduce((acc, element) => acc
    + (element.stats.celebrationsCount), 0);

  const allStates = workspaces
    .reduce((acc, w) => ({
      ...acc,
      [w.teamId ? w.state : '__teams']: ((acc[w.teamId ? w.state : '__teams'] || 0) + 1),
    }), {});
  const allStatesTable = Object.keys(allStates)
    .reduce((acc, element) => [...acc, {
      name: [element],
      value: allStates[element],
    }], [])
    .sort((a, b) => b.value - a.value);
  const allStatesFromThisWeek = weekWorkspaces.reduce((acc, w) => ({
    ...acc,
    [w.teamId ? w.state : '__teams']: ((acc[w.teamId ? w.state : '__teams'] || 0) + 1),
  }), {});
  const allStatesFromThisWeekTable = Object.keys(allStatesFromThisWeek)
    .reduce((acc, element) => [...acc, {
      name: [element],
      value: allStatesFromThisWeek[element],
    }], [])
    .sort((a, b) => b.value - a.value);
  return (
    <div className="Workspace_stats_wrapper">
      <div className="Workspace_stats_inner">
        <StatisticVignette
          icon="🔼"
          title=" New workspaces Today"
          content={todayWorkspaces.length}
          subcontent={`${weekWorkspaces.length} this week`}
        />
        <StatisticVignette
          icon="🟢"
          title="Live workspaces"
          content={liveWorkspaces.length}
          subcontent={`🟠 ${pendingWorkspaces.length} pending   🔴 ${uninstalledWorkspaces.length} uninstalled`}
        />
        <StatisticVignette
          icon="🎂"
          title="% of Bday collected"
          content={parseFloat((100 * (totalCountBirthdays / totalUsersBirthdays)).toFixed(1))}
          showPercentage
          subcontent={`${numberWithCommas(totalCountBirthdays)} / ${numberWithCommas(totalUsersBirthdays)}`}
        />
        <StatisticVignette
          icon="💼"
          title="% of Anniversaries collected"
          content={parseFloat((100 * (totalCountAnniversaries / totalUsersAnniversaries))
            .toFixed(1))}
          showPercentage
          subcontent={`${numberWithCommas(totalCountAnniversaries)} / ${numberWithCommas(totalUsersAnniversaries)}`}
        />
        <StatisticVignette
          icon="🔄"
          title="% Auto-collect"
          content={parseFloat((100 * (percentAutocollect.length / liveWorkspaces.length))
            .toFixed(1))}
          showPercentage
          subcontent={`${numberWithCommas(percentAutocollect.length)} / ${numberWithCommas(liveWorkspaces.length)}`}
        />
        <StatisticVignette
          icon="🏆"
          title="Total celebrations"
          content={parseFloat(totalCelebrations.toFixed(1))}
          subcontent={`AVG per workspace is ${parseFloat((totalCelebrations / workspaces.length).toFixed(1))}`}
        />
        <StatisticVignette
          icon="🧲"
          title="Inbound origins"
          content={
            (
              <div
                style={{
                  fontSize: '12px',
                  fontWeight: 'bold',
                  textAlign: 'left',
                  ...(allStatesTable.length > 6 ? {
                    maxHeight: '100px',
                    overflowY: 'scroll',
                  } : {}),
                }}
              >
                {allStatesTable.map((e, i) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <div key={i}>
                    {/* eslint-disable-next-line no-nested-ternary */}
                    {`${(i === 0) ? '🥇' : (i === 1 ? '🥈' : (i === 2 ? '🥉' : '◻️'))} ${e.name}: ${e.value}`}
                  </div>
                ))}
              </div>
            )
          }
          subcontent="All times"
        />
        <StatisticVignette
          icon="🧲"
          title="Inbound origins"
          content={
            (
              <div
                style={{
                  fontSize: '12px',
                  fontWeight: 'bold',
                  textAlign: 'left',
                  ...(allStatesFromThisWeekTable.length > 6 ? {
                    maxHeight: '100px',
                    overflowY: 'scroll',
                  } : {}),
                }}
              >
                {allStatesFromThisWeekTable.map((e, i) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <div key={i}>
                    {/* eslint-disable-next-line no-nested-ternary */}
                    {`${(i === 0) ? '🥇' : (i === 1 ? '🥈' : (i === 2 ? '🥉' : '◻️'))} ${e.name}: ${e.value}`}
                  </div>
                ))}
              </div>
            )
          }
          subcontent="This week"
        />

      </div>
    </div>
  );
}

WorkspacesStat.propTypes = {
  workspaces: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default WorkspacesStat;
