import React from 'react';
import { Table } from 'antd';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import qs from 'qs';
import { useQuery } from '@tanstack/react-query';
import { countPastEvents, listPastEvents } from '../../services/Admin';
import { dateFromMongoId } from '../../utils/mongo-id';

function History() {
  const location = useLocation();
  const search = qs.parse(location.search, { ignoreQueryPrefix: true });
  const {
    isLoading,
    data: events,
  } = useQuery(
    ['listPastEvents', search.teamId],
    () => listPastEvents(search.teamId),
  );
  const {
    data: count,
    isLoading: isCountLoading,
  } = useQuery(
    ['countPastEvents', search.teamId],
    () => countPastEvents(search.teamId),
  );
  return (
    <div id="History">
      <Table
        dataSource={events}
        loading={isLoading}
        pagination={false}
        rowKey="_id"
        size="small"
        scroll={{ x: 1500 }}
      >
        <Table.Column
          title={`TeamId${isCountLoading ? '' : ` (${count})`}`}
          dataIndex="teamId"
          key="teamId"
        />
        <Table.Column
          title="Date"
          dataIndex="_id"
          key="_id"
          render={(mongoId) => moment(dateFromMongoId(mongoId)).format('YYYY-MM-DD @ HH:mm')}
        />
        <Table.Column
          title="Events"
          dataIndex="_id"
          key="_id"
          render={(_, event) => (
            Object
              .entries(
                event.events
                  .reduce((obj, e) => ({
                    ...obj,
                    [e.type]: [
                      ...(obj[e.type] || []),
                      e.userId,
                    ],
                  }), {}),
              )
              .map(([type, userIds]) => `${type} of ${userIds.join('+')}`)
              .join(', ')
          )}
        />
      </Table>
    </div>
  );
}

export default History;
