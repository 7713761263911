import React from 'react';
import { Button, Form, Input } from 'antd';
import qs from 'qs';
import { useHistory, useLocation } from 'react-router-dom';
import { login } from '../services/Admin';
import './Auth.less';

function Auth() {
  const history = useHistory();
  const location = useLocation();

  const onFinish = async (values) => {
    try {
      const { _id: sessionId } = await login(values);
      localStorage.setItem('sessionId', sessionId);
      const search = qs.parse(location.search || '', { ignoreQueryPrefix: true });
      const param = search.next
        ? JSON.parse(search.next)
        : '/';
      history.push(param);
    } catch (error) {
      // do nothing
    }
  };

  return (
    <div>
      <div className="Login_wrapper">
        <div className="Login_inner">
          <div>
            <img
              src="/logo_with_background.png"
              alt="billy birthday logo"
              className="Login_logo"
            />
          </div>
          <h1>Admin panel</h1>
          <Form
            onFinish={onFinish}
            autoComplete="off"
            layout="vertical"
          >
            <Form.Item
              label="Email"
              name="email"
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Password"
              name="password"
            >
              <Input.Password />
            </Form.Item>
            <Form.Item>
              <Button block type="primary" htmlType="submit">
                Login
              </Button>
            </Form.Item>
          </Form>
        </div>

      </div>
    </div>
  );
}

export default Auth;
