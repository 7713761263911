import React, { useState } from 'react';
import qs from 'qs';
import { useLocation, useHistory } from 'react-router-dom';
import {
  Table, Pagination, Checkbox, Avatar, Tag, Input, Button, message, Select,
} from 'antd';
import { SearchOutlined, SendOutlined } from '@ant-design/icons';
import { useQuery } from '@tanstack/react-query';
import debounce from 'lodash.debounce';
import { listUsers, countUsers, sendMessage } from '../../services/Admin';
import SendMessageModal from '../../components/SendMessageModal';

const formatSlackUser = (u) => ({
  _id: u._id,
  id: u.userId,
  entityId: u.teamId,
  name: u.data.real_name || u.data.profile.real_name,
  profilePic: u.data.profile.image_48,
  email: u.data.profile.email,
  jobTitle: u.data.profile.title,
  isAdmin: u.data.is_admin,
  isBot: u.data.is_bot,
  isDeleted: u.data.deleted,
  tz: u.data.tz,
});

const formatTeamsUser = (u) => ({
  _id: u._id,
  id: u.aadObjectId,
  entityId: u.tenantId,
  name: u.data.name,
  email: u.data.email,
});

function Users() {
  // Pagination
  const location = useLocation();
  const history = useHistory();
  const search = qs.parse(location.search, { ignoreQueryPrefix: true });
  const currentPage = +search.page || 1;
  const onPaginationChange = (page) => {
    const newSearch = {
      ...search,
      page,
    };
    history.push({
      ...location,
      search: qs.stringify(newSearch),
    });
  };
  const onSearchChange = (e) => {
    const newSearch = {
      ...search,
      q: e.target.value,
      page: 1,
    };
    history.push({
      ...location,
      search: qs.stringify(newSearch),
    });
  };
  const setUserTeam = (userId, teamId) => {
    const userTeam = userId ? `${userId}_${teamId}` : '';
    if (userTeam === search.userTeam) return;
    const newSearch = {
      ...search,
      userTeam,
    };
    history.push({
      ...location,
      search: qs.stringify(newSearch),
    });
  };
  const type = search.type || 'slack';
  const onTypeChange = (value) => {
    if (value !== type) {
      delete search.page;
      delete search.teamId;
    }
    const newSearch = {
      ...search,
      type: value,
    };
    history.push({
      ...location,
      search: qs.stringify(newSearch),
    });
  };
  const [isSendingMessage, setIsSendingMessage] = useState(false);
  const onSendMessage = async ({ text }) => {
    try {
      if (!text) return;
      const [userId, teamId] = search.userTeam.split('_');
      setIsSendingMessage(true);
      await sendMessage(userId, teamId, text);
      message.success('Message sent!');
      setUserTeam('', '');
    } catch (error) {
      message.error('Unexpected error while sending message!');
    }
    setIsSendingMessage(false);
  };

  // Filters
  const [onlyEligible, setOnlyEligible] = useState(true);

  // Data fetching
  const { isLoading, data: rawUsers } = useQuery(
    ['listUsers', type, currentPage, search.teamId, type, onlyEligible, search.q],
    () => listUsers(type, currentPage, search.teamId, onlyEligible, search.q),
    { cacheTime: 0 },
  );
  const { data: count, isLoading: isCountLoading } = useQuery(
    ['countUsers', type, search.teamId, onlyEligible, search.q],
    () => countUsers(type, search.teamId, onlyEligible, search.q),
    { cacheTime: 0 },
  );

  const users = rawUsers ? rawUsers.map(type === 'slack' ? formatSlackUser : formatTeamsUser) : [];

  return (
    <div id="Users">
      <div className="Filters">
        <Checkbox checked={onlyEligible} onChange={(e) => setOnlyEligible(e.target.checked)}>
          Only Eligible
        </Checkbox>
        <Input
          prefix={(
            <SearchOutlined />
          )}
          placeholder="Search in name, email and userId"
          defaultValue={search.q || ''}
          style={{ width: 260, marginLeft: 16 }}
          onChange={debounce(onSearchChange, 500)}
        />
        <Select
          placeholder="Platform"
          defaultValue={type}
          style={{ width: 200, marginLeft: 16 }}
          onChange={onTypeChange}
        >
          <Select.Option value="slack">Slack</Select.Option>
          <Select.Option value="teams">Teams</Select.Option>
        </Select>
        <div style={{ flex: 1 }} />
        <Pagination
          current={currentPage}
          onChange={onPaginationChange}
          total={count}
          pageSize={20}
          showSizeChanger={false}
        />
      </div>
      <Table
        dataSource={users}
        loading={isLoading}
        pagination={false}
        rowKey="_id"
        size="small"
        scroll={{ x: 1500 }}
      >
        <Table.Column
          title={`User ID${isCountLoading ? '' : ` (${count})`}`}
          dataIndex="id"
          key="id"
        />
        <Table.Column title={type === 'slack' ? 'teamId' : 'tenantId'} dataIndex="entityId" key="_id" />
        <Table.Column
          title="name"
          dataIndex="_id"
          key="_id"
          render={(_, user) => (
            <span>
              {user.profilePic && (
                <Avatar
                  src={user.profilePic}
                  size={28}
                  style={{ marginRight: '8px' }}
                />
              )}
              {user.name}
              {user.isAdmin && <Tag color="red" style={{ marginLeft: '8px' }}>ADMIN</Tag>}
              {user.isBot && <Tag color="blue" style={{ marginLeft: '8px' }}>BOT</Tag>}
              {user.isDeleted && ' ☠️'}
            </span>
          )}
        />
        <Table.Column
          title="Email"
          dataIndex="_id"
          key="_id"
          render={(_, user) => user.email}
        />
        {type === 'slack' && (
          <>
            <Table.Column
              title="Job Title"
              dataIndex="_id"
              key="_id"
              render={(_, user) => user.jobTitle}
            />
            <Table.Column
              title="Timezone"
              dataIndex="_id"
              key="_id"
              render={(_, user) => user.tz}
            />
            <Table.Column
              title=""
              dataIndex="_id"
              key="_id"
              render={(_, user) => (
                <Button
                  type="link"
                  icon={<SendOutlined />}
                  onClick={() => setUserTeam(user.id, user.entityId)}
                />
              )}
            />
          </>
        )}
      </Table>
      <SendMessageModal
        show={!!search.userTeam}
        onCancel={() => setUserTeam('', '')}
        onSubmit={onSendMessage}
        loading={isSendingMessage}
      />
    </div>
  );
}

export default Users;
