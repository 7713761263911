/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable object-curly-newline */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import qs from 'qs';
import PropTypes from 'prop-types';
import {
  Table, Tag, Tooltip, message, Dropdown, Menu, Checkbox, Radio,
} from 'antd';
import { DeleteOutlined, MoreOutlined, UserOutlined, DollarOutlined, CrownOutlined } from '@ant-design/icons';
import moment from 'moment';
import { useQuery } from '@tanstack/react-query';
import {
  deleteWorkspace,
  listMsOrganizationsWithStats, countMsOrganizations, grantFreePlan,
} from '../../services/Admin';
import { dateFromMongoId } from '../../utils/mongo-id';
import DeleteConfirmModal from '../../components/DeleteConfirmModal';
import { COMPETITOR } from '../../services/Constants';
import WorkspacesStat from '../../components/WorkspacesStat';

// eslint-disable-next-line react/function-component-definition
const divMaxWidth = (maxWidth = 110) => (x) => (
  <div
    style={{
      maxWidth,
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    }}
  >
    {x}
  </div>
);

function SlackStatus({ workspace: w }) {
  const hasChannel = w.version >= 'V3' ? w.channels.length > 0 : w.channel;
  const channelStr = w.version >= 'V3'
    ? w.channels.map((c) => (
      <div key={c.id}>
        {c.info.is_private ? '🔒' : '#️⃣'}
        {c.info.name}
        {` (${c.scope.toLowerCase()} - 🔄 ${c.autoCollect ? 'Yes' : 'No'}${c.scope === 'CHANNEL' ? ` - ${c.members.length}` : ''})`}
      </div>
    ))
    : (
      <div>
        {`#${w.channelInfo?.name}`}
      </div>
    );
  return (
    <span>
      {/* eslint-disable-next-line no-nested-ternary */}
      {w.status === 'LIVE' ? (w.installedAt ? (
        <span>
          <Tooltip
            title={(
              <div>
                {`Live ${w.version}`}
                {channelStr}
              </div>
            )}
          >
            🟢
          </Tooltip>
        </span>
      ) : (
        <Tooltip title={`Pending ${w.version}`}>
          🟠
        </Tooltip>
      )) : (
        <Tooltip
          title={(
            <div>
              {`Uninstalled ${w.version}`}
              {hasChannel && channelStr}
            </div>
          )}
        >
          🔴
        </Tooltip>
      )}
    </span>
  );
}

function TeamsStatus({ workspace: w }) {
  const liveTeams = w.teams.filter((t) => (
    t.status === 'LIVE'
    && t.installedAt
  )).length;
  const waitingForSetup = w.teams.filter((t) => (
    t.status === 'LIVE'
    && !t.installedAt
  )).length;
  const teamsInfo = w.teams.map((t) => (
    <div key={t.infos.id}>
      {`${t.status === 'LIVE' && t.installedAt ? '🟢' : (t.status === 'LIVE' ? '🟠' : '🔴')} ${t.infos.name} - 🔄 ${t.autoCollect ? 'Yes' : 'No'} - ${t.memberIds.length}`}
    </div>
  ));
  return (
    <span>
      <Tooltip title={teamsInfo}>
        {liveTeams > 0 && '🟢'}
        {liveTeams === 0 && waitingForSetup > 0 && '🟠'}
        {liveTeams === 0 && waitingForSetup === 0 && '🔴'}
      </Tooltip>
    </span>
  );
}

SlackStatus.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  workspace: PropTypes.object.isRequired,
};
TeamsStatus.propTypes = SlackStatus.propTypes;

const formatSlackWorkspace = (w) => ({
  _id: w._id,
  type: 'slack',
  stats: w.stats,
  id: w.teamId,
  installerUserId: w.installerUserId,
  installDate: moment(dateFromMongoId(w._id))
    .format('YYYY-MM-DD @ HH:mm'),
  name: w.name,
  domain: w.stats.domain,
  // For status display + hover
  status: w.status,
  version: w.version,
  installedAt: w.installedAt,
  channels: w.channels,
  channel: w.channel,
  channelInfo: w.channelInfo,
  // For status display + hover (end)
  lang: w.lang,
  autoCollect: (
    w.installedAt
      ? (
        w.version >= 'V3'
          ? (w.channels[0]?.autoCollect ? 'Yes' : 'No')
          : (w.autoCollect ? 'Yes' : 'No')
      )
      : '-'
  ),
  workAnniversary: w.workAnniversary,
  origin: w.state,
  gclid: w.gclid,
  hasSeenPricing: (typeof w.hasSeenPricing === 'boolean' ? {
    true: 'Yes',
    false: 'No',
  }[w.hasSeenPricing] : '-'),
  // For billing/free/trial display
  freeTrialStartDate: w.freeTrialStartDate,
  stripeSubscription: w.stripeSubscription,
  stripeCustomer: w.stripeCustomer,
  freeForever: w.freeForever,
  encryptedId: w.encryptedId,
});

const formatMsOrg = (w) => ({
  _id: w._id,
  type: 'teams',
  stats: w.stats,
  id: w.tenantId,
  installerUserId: w.installerUserId,
  installDate: moment(dateFromMongoId(w._id))
    .format('YYYY-MM-DD @ HH:mm'),
  name: '',
  domain: w.stats.domain,
  // For status display + hover
  teams: w.teams,
  // For status display + hover (end)
  lang: 'en',
  autoCollect: w.teams[0]?.autoCollect ? 'Yes' : 'No',
  workAnniversary: !!w.teams.find((t) => (
    t.status === 'LIVE'
    && t.installedAt
    && t.workAnniversary
  )),
  origin: '-',
  hasSeenPricing: '-',
  // For billing/free/trial display
  freeTrialStartDate: w.freeTrialStartDate,
  stripeSubscription: w.stripeSubscription,
  stripeCustomer: w.stripeCustomer,
  freeForever: w.freeForever,
  encryptedId: w.encryptedId,
});

function Workspaces(props) {
  const location = useLocation();
  const search = qs.parse(location.search, { ignoreQueryPrefix: true });
  const isSlackLoading = false;
  const allWorkspaces = [];
  const {
    isLoading: isMsLoading,
    data: allOrgs,
    data: refetchListTeams,
  } = useQuery(['listMsOrganizationsWithStats'], listMsOrganizationsWithStats);
  const isLoading = isSlackLoading || isMsLoading;
  const isSlackCountLoading = false;
  const slackCount = 0;
  const {
    data: teamsCount,
    isLoading: isTeamsCountLoading,
    refetch: refetchCountTeams,
  } = useQuery(['countMsOrganizations'], countMsOrganizations);
  const [workspaceToDelete, setWorkspaceToDelete] = useState();
  const [entityToGrantFreePlan, setEntityToGrantFreePlan] = useState();
  const showSlack = !search.type || search.type === 'slack';
  const showTeams = !search.type || search.type === 'teams';
  const isCountLoading = (
    (showSlack && isSlackCountLoading)
    || (showTeams && isTeamsCountLoading)
  );
  const count = (
    (showSlack ? slackCount : 0)
    + (showTeams ? teamsCount : 0)
  );

  const [isDeletingWorkspace, setIsDeletingWorkspace] = useState(false);
  const onDeleteWorkspace = async () => {
    try {
      setIsDeletingWorkspace(true);
      await deleteWorkspace(workspaceToDelete._id);
      message.success('Workspace deleted!');
      setWorkspaceToDelete(undefined);
    } catch (error) {
      message.error('Unexpected error while deleting workspace!');
    }
    setIsDeletingWorkspace(false);
  };
  const [isGrantingFreePlan, setIsGrantingFreePlan] = useState(false);
  const onGrantFreePlan = async () => {
    try {
      setIsGrantingFreePlan(true);
      await grantFreePlan(entityToGrantFreePlan._id);
      message.success('Free forever granted!');
      setEntityToGrantFreePlan(undefined);
      refetchListTeams();
      refetchCountTeams();
    } catch (error) {
      message.error('Unexpected error while granting free plan!');
    }
    setIsGrantingFreePlan(false);
  };

  const workspacesForStats = allWorkspaces
    ? [
      ...(showSlack ? (allWorkspaces || []) : []),
      ...(showTeams ? (allOrgs || []) : []),
    ].filter((el) => (
      el.stats.usersCount >= 10
      && (search.freeTrial ? el.freeTrialStartDate : true)
    ))
    : [];

  const dataSource = [
    ...(showSlack ? (
      search.freeTrial ? (allWorkspaces || []).filter((w) => w.freeTrialStartDate).sort((a, b) => (
        new Date(b.freeTrialStartDate) - new Date(a.freeTrialStartDate)
      )) : (search.paid ? (allWorkspaces || []).filter((a) => !!a.stripeSubscription)
        .sort((a, b) => (
          new Date(b.freeTrialStartDate) - new Date(a.freeTrialStartDate)
        ))
        : (allWorkspaces || []))
    ) : []).map(formatSlackWorkspace),
    ...(showTeams ? (
      search.freeTrial ? (allOrgs || []).filter((w) => w.freeTrialStartDate).sort((a, b) => (
        new Date(b.freeTrialStartDate) - new Date(a.freeTrialStartDate)
      )) : (search.paid ? (allOrgs || []).filter((a) => !!a.stripeSubscription).sort((a, b) => (
        new Date(b.freeTrialStartDate) - new Date(a.freeTrialStartDate)
      ))
        : (allOrgs || []))
    ) : []).map(formatMsOrg),
  ].sort((a, b) => {
    if (search.freeTrial) return new Date(b.freeTrialStartDate) - new Date(a.freeTrialStartDate);
    if (a._id < b._id) return 1;
    if (a._id > b._id) return -1;
    return 0;
  });

  const isFreeTrialOnly = search.freeTrial && search.freeTrial === 'true';
  const isPaidCustomerOnly = search.paid && search.paid === 'true';
  const onChangeOnlyTrial = (e) => {
    const newSearch = {
      ...search,
      freeTrial: e.target.checked ? 'true' : 'false',
    };
    if (newSearch.freeTrial === 'false') {
      delete newSearch.freeTrial;
    }
    props.history.push({
      ...location,
      search: qs.stringify(newSearch),
    });
  };

  const onChangeOnlyPaid = (e) => {
    const newSearch = {
      ...search,
      paid: e.target.checked ? 'true' : 'false',
    };
    if (newSearch.paid === 'false') {
      delete newSearch.paid;
    }
    props.history.push({
      ...location,
      search: qs.stringify(newSearch),
    });
  };

  const typeInSearchParams = search.type || 'all';
  const onChanceType = (e) => {
    const newSearch = {
      ...search,
      type: e.target.value,
    };
    if (newSearch.type === 'all') {
      delete newSearch.type;
    }
    props.history.push({
      ...location,
      search: qs.stringify(newSearch),
    });
  };

  return (
    <div id="Workspaces">
      {workspacesForStats && <WorkspacesStat workspaces={workspacesForStats} />}
      <div style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '12px',
        background: '#d2ebff',
        borderRadius: '10px',
        marginBottom: '12px',
      }}
      >
        <Radio.Group
          options={[{
            label: 'All',
            value: 'all',
          }, {
            label: 'Microsoft Teams',
            value: 'teams',
          },
          {
            label: 'Slack',
            value: 'slack',
          }]}
          onChange={onChanceType}
          value={typeInSearchParams}
          optionType="button"
          buttonStyle="solid"
          style={{ marginRight: '12px' }}

        />
        <Checkbox
          checked={isFreeTrialOnly}
          onChange={onChangeOnlyTrial}
        >
          Only Free Trials
        </Checkbox>
        <Checkbox
          checked={isPaidCustomerOnly}
          onChange={onChangeOnlyPaid}
        >
          Only Paid customers
        </Checkbox>

      </div>
      <Table
        dataSource={dataSource}
        loading={isLoading}
        pagination={false}
        rowKey="_id"
        size="small"
        scroll={{ x: 1500 }}
      >
        <Table.Column
          title={`ID${isCountLoading ? '' : ` (${search.freeTrial ? dataSource.length : count})`}`}
          dataIndex="id"
          render={divMaxWidth()}
        />
        <Table.Column
          title="Install User"
          dataIndex="installerUserId"
          key="_id"
          render={(e, item) => {
            const n = <a href={`/#/users?q=${e}&type=${item.type}`}>{e}</a>;
            return (
              <div
                style={{
                  maxWidth: 110,
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                }}
              >
                {n}
              </div>
            );
          }}
        />
        <Table.Column
          title="Install Date"
          dataIndex="installDate"
        />
        <Table.Column
          title=""
          dataIndex="_id"
          key="_id"
          render={(_, w) => (
            w.type === 'slack'
              ? <SlackStatus workspace={w} />
              : <TeamsStatus workspace={w} />
          )}
        />
        <Table.Column
          title=""
          dataIndex="type"
          key="_id"
          render={(type) => (
            <img
              src={{
                slack: 'https://billybirthday.com/img/slack.png',
                teams: 'https://billybirthday.com/img/teams_cropped.png',
              }[type]}
              alt={type}
              style={{ width: 10 }}
            />
          )}
        />
        <Table.Column
          title="Name"
          dataIndex="name"
          key="_id"
          render={(name, workspace) => (
            <div
              style={{
                maxWidth: 410,
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
              }}
            >
              {name}
              <Tag style={{ marginLeft: '8px' }}>{workspace.stats.domain}</Tag>
            </div>
          )}
        />
        <Table.Column
          title="Lang"
          dataIndex="lang"
          key="_id"
        />
        <Table.Column
          title="🔄"
          dataIndex="autoCollect"
          key="_id"
        />
        <Table.Column
          title="Stats 🎂"
          dataIndex="_id"
          key="_id"
          render={(_, workspace) => (
            <span>
              <Tooltip
                placement="right"
                title={(
                  <div>
                    <div>{`(${(workspace.stats.birthdayCount - workspace.stats.birthdayOptOutCount)}/${(workspace.stats.usersCount - workspace.stats.birthdayOptOutCount)})`}</div>
                    <div>{`${workspace.stats.birthdayOptOutCount} opt-out`}</div>
                  </div>
                )}
              >
                {((workspace.stats.usersCount - workspace.stats.birthdayOptOutCount) === 0) ? '0%' : `${(100 * ((workspace.stats.birthdayCount - workspace.stats.birthdayOptOutCount) / (workspace.stats.usersCount - workspace.stats.birthdayOptOutCount))).toFixed(2)}%`}
              </Tooltip>
            </span>
          )}
        />
        <Table.Column
          title="Stats 💼"
          dataIndex="_id"
          key="_id"
          render={(_, workspace) => (
            <span>
              {workspace.workAnniversary ? (
                <Tooltip
                  placement="right"
                  title={(
                    <div>
                      <div>{`(${workspace.stats.workAnniversaryCount}/${(workspace.stats.usersCount - workspace.stats.workAnniversaryOptOutCount)})`}</div>
                      <div>{`${workspace.stats.workAnniversaryOptOutCount} opt-out`}</div>
                    </div>
                  )}
                >
                  {`${(100 * (workspace.stats.workAnniversaryCount / (workspace.stats.usersCount - workspace.stats.workAnniversaryOptOutCount))).toFixed(2)}%`}
                </Tooltip>
              ) : '-'}
            </span>
          )}
        />
        <Table.Column
          title="#🏆"
          dataIndex="_id"
          key="_id"
          render={(_, workspace) => workspace.stats.celebrationsCount}
        />
        <Table.Column
          title="Origin"
          dataIndex="origin"
          key="_id"
          render={(v, w) => `${v}${w.gclid ? ' + gclid' : ''}`}
        />
        <Table.Column
          title="⚔️"
          dataIndex="_id"
          key="_id"
          render={(_, w) => (
            w.stats.competitors?.length ? (
              w.stats.competitors.map((c) => (
                <img
                  src={COMPETITOR[c.name]?.img}
                  alt={c.name}
                  key={c.name}
                  style={{
                    width: 20,
                    borderRadius: 20,
                    marginRight: 4,
                    ...(c.deleted ? {
                      filter: 'grayscale(1)',
                      opacity: 0.3,
                    } : {}),
                  }}
                />
              ))
            ) : '-'
          )}
        />
        <Table.Column
          title="👀💸"
          dataIndex="hasSeenPricing"
          key="_id"
        />
        <Table.Column
          title="💰"
          dataIndex="_id"
          key="_id"
          render={(_, w) => (
            w.freeTrialStartDate
              ? (
                <span
                  style={{
                    color: moment(w.freeTrialStartDate)
                      .isBefore(moment()
                        .subtract(30, 'days')) ? 'red' : undefined,
                  }}
                >
                  {moment(w.freeTrialStartDate)
                    .format('YYYY-MM-DD')}
                </span>
              )
              : (w.stripeSubscription ? '🤑' : (w.freeForever ? '♾️' : '-'))
          )}
        />

        <Table.Column
          title=""
          dataIndex="id"
          key="_id"
          render={(id, workspace) => (
            <a href={`#/users?teamId=${id}&type=${workspace.type}`}>
              {`View users (${workspace.stats.usersCount})`}
            </a>
          )}
        />
        <Table.Column
          title=""
          dataIndex="_id"
          key="_id"
          className="DeleteColumn"
          render={(_, w) => (
            <div>
              <Dropdown
                trigger="click"
                overlay={
                  (
                    <Menu>
                      {w.type === 'slack' && (
                        <Menu.Item
                          danger
                          onClick={() => setWorkspaceToDelete(w)}
                        >
                          <DeleteOutlined />
                          &nbsp;Delete
                        </Menu.Item>
                      )}
                      {w.stripeCustomer && (
                        <Menu.Item>
                          <a href={`https://api.billybirthday.com/customer-portal/${w.encryptedId}`}>
                            <UserOutlined />
                            &nbsp;Customer Portal
                          </a>
                        </Menu.Item>
                      )}
                      {w.freeTrialStartDate && (
                        <Menu.Item>
                          <a href={`https://api.billybirthday.com/subscribe/${w._id}`}>
                            <DollarOutlined />
                            &nbsp;Payment Link
                          </a>
                        </Menu.Item>
                      )}
                      {!w.freeForever && !w.stripeSubscription && (
                        <Menu.Item onClick={() => setEntityToGrantFreePlan(w)}>
                          <CrownOutlined />
                          &nbsp;Grant Free Forever
                        </Menu.Item>
                      )}
                    </Menu>
                  )
                }
              >
                <div
                  style={{
                    padding: '4px',
                    cursor: 'pointer',
                  }}
                >
                  <MoreOutlined />
                </div>
              </Dropdown>
            </div>
          )}
        />
      </Table>
      {workspaceToDelete && (
        <DeleteConfirmModal
          strConfirm={workspaceToDelete ? workspaceToDelete.name : ''}
          onSubmit={onDeleteWorkspace}
          onCancel={() => setWorkspaceToDelete(undefined)}
          loading={isDeletingWorkspace}
        />
      )}
      {entityToGrantFreePlan && (
        <DeleteConfirmModal
          strConfirm={entityToGrantFreePlan ? entityToGrantFreePlan.name || entityToGrantFreePlan.domain : ''}
          onSubmit={onGrantFreePlan}
          onCancel={() => setEntityToGrantFreePlan(undefined)}
          loading={isGrantingFreePlan}
        />
      )}
    </div>
  );
}

export default Workspaces;
