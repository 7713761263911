// eslint-disable-next-line import/prefer-default-export
export const COMPETITOR = {
  birthdaybot: {
    img: 'https://avatars.slack-edge.com/2022-09-02/4028655733237_69a8eae2fd9c7ee3b6b9_48.png',
  },
  celebrations: {
    img: 'https://avatars.slack-edge.com/2023-05-17/5307136771632_23fc04d1c85ee699a7ab_48.png',
  },
  celebrations_by_airsp: {
    img: 'https://avatars.slack-edge.com/2023-05-16/5288408943857_dd9163fa2fbd9025ef1a_48.png',
  },
};
