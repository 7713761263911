import React from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import Routing from './Routing';
import './App.less';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Routing />
    </QueryClientProvider>
  );
}

export default App;
